import React from "react"
import Layout from "../components/layout"
import InfoSection from "../components/InfoSection"

const Katalog: React.FC = () => (
  <Layout>
    <InfoSection title="Kontakt" nofollow>
      <p className="rwd-center">
        <strong>Impla Smile Sp. z o.o. Sp. k.</strong>
        <br />
        <br />
        Ul Księcia Ziemowita 53 bud. 5A lok. 2A
        <br />
        03-885 Warszawa
        <br />
        NIP: 524 288 23 19
        <br />
        <br />
        Biuro:
        <br /> 22&nbsp;299 21 99
        <br />
        <br />
        Dział handlowy:
        <br />
        790 502 199
        <br />
        790 552 199
        <br />
        790 282 199
      </p>
    </InfoSection>
  </Layout>
)

export default Katalog
